<template>
  <div class="flex items-center mt-2">
    <div class="flex items-center w-56 select-none text-sm leading-tight">
      {{ filter.label }}
    </div>
    <el-slider
      v-model="rangeValue"
      class="range-slider"
      range
      :min="min"
      :max="max"
      :disabled="disabled"
      :placeholder="filter.placeholder"
      @change="handleSliderChange"
    />
  </div>
</template>

<script>
export default {
  props: {
    filter: {
      type: Object,
      default: () => ({})
    },
    modelValue: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      rangeValue: [],
      defaultMin: 0,
      defaultMax: 1000
    }
  },
  computed: {
    disabled() {
      if (!this.modelValue) return false
      return this.modelValue.from === this.modelValue.to
    },
    min() {
      return this.hasValidValue(this.filter, 'min')
        ? this.filter.min
        : this.defaultMin
    },
    max() {
      return this.hasValidValue(this.filter, 'max')
        ? this.filter.max
        : this.defaultMax
    },
    from() {
      return this.hasValidValue(this.modelValue, 'from')
        ? this.modelValue.from
        : this.min
    },
    to() {
      return this.hasValidValue(this.modelValue, 'to')
        ? this.modelValue.to
        : this.max
    }
  },
  watch: {
    modelValue: function () {
      this.rangeValue = [this.from, this.to]
    },
    'filter.min'() {
      this.rangeValue = [this.from, this.rangeValue[1]]
    },
    'filter.max'() {
      this.rangeValue = [this.rangeValue[0], this.to]
    }
  },
  mounted() {
    this.rangeValue = [this.from, this.to]
  },
  methods: {
    handleSliderChange([from, to]) {
      this.$emit('update:modelValue', { from, to })
    },
    hasValidValue(object, key) {
      const value = object && object[key]
      return value !== undefined && value !== null
    }
  }
}
</script>

<style scoped>
.range-slider {
  width: calc(100% - 150px);
}
</style>
